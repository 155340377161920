<template>
   <div class="main-view">
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="姓名:" prop="name">
          <el-input clearable v-model="table.params.name" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="电话:" prop="account">
          <el-input clearable v-model="table.params.account" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="所在区域:" prop="area_arr">
          <el-cascader
            style="width:100%;"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <el-form-item label="排序:" prop="sort">
          <el-radio-group v-model="table.params.sort" @change="getTable">
            <el-radio-button :label="1">服务次数<icon class="el-icon-arrow-down"></icon></el-radio-button>
            <el-radio-button :label="2">评价次数<icon class="el-icon-arrow-down"></icon></el-radio-button>
            <el-radio-button :label="3">综合得分<icon class="el-icon-arrow-down"></icon></el-radio-button>
          </el-radio-group>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
    </div>

    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="field"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
    >
      <template v-slot:province_str="{row}">
        <span>{{row.province_str}}{{row.city_str}}{{row.area_str}}</span>
      </template>
      <template v-slot:type="{row}">
        <span>{{toStr({0:'未填',1:'学生',2:'自由职业',3:'宝妈',4:'全职'},row.type)}}</span>
      </template>
      <template v-slot:kinds="{row}">
        <span v-for="item in row.kinds" :key="item">{{item ? item+'、' : ''}}</span>
      </template>
      <template v-slot:source="{row}">
        <span>{{row.source ? toStr({1:'微信', 2:'IOS', 3:'安卓', 4:'支付宝',5:'IOT',6:'设备端'}, row.source) : '' }}</span>
      </template>
    </VTable>
    
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import { mixinTable } from '@/mixins/table.js'
export default {
  name: 'UserList',
  components: {
    VTable,
  },
  mixins: [mixinTable],
  data() {
    return {
      field: [
        { name: "nickname", label: "姓名",  width: "160", hidden: false },
        { name: "account", label: "联系电话", hidden: false },
        { name: "province_str", label: "所在地区", hidden: false },
        { name: "type", label: "身份", hidden: false },
        { name: "work_age", label: "工龄/年", hidden: false },
        { name: "kinds", label: "擅长工种", showTooltip: true,  hidden: false },
        { name: "service_num", label: "服务次数", hidden: false },
        { name: "score_num", label: "评价次数", hidden: false },
        { name: "user_score", label: "综合得分", hidden: false },
      ],
      table: {
        loading: false,
        params: {
          sort: '', // 排序 1:服务次数 2:评价次数 3:平均分
          province: '',
          city: '',
          area: '',
          area_arr: '',
          name: '',
          account: '',
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      areaArr: [],

    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _area_arr = this.table.params.area_arr;
      this.table.params.province = _area_arr[0];
      this.table.params.city = _area_arr[1];
      this.table.params.area = _area_arr[2];
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/admin/flex_rank/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    // 查看
    showDetail(row, userType) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row, userType)
      dom = null
    },
    // 关闭
    handleClose() {
      this.$confirm("是否关闭此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    // 删除
    remove(row) {
      this.$confirm("是否删除此条记录？", "删除", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/admin/article/delete', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    }
  }
}
</script>